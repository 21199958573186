@import "./culori.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.container {
  width: 70%;
  margin: 0 auto;
}

p,
a {
  margin: 0;
  padding: 0;
  text-decoration: none;
  // color: black; // pentru property-ul [disabled]
}



.close-button {
  color: gray;
}

.color-gri {
  color: $gri-deschis1;
}

.rosu-deschis {
  color: $rosu-deschis;
}